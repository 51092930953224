import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "./ProvideAuth";
import SignIn from "navigation/Auth/components/SignIn";
import SignUp from "./components/SignUp";
import ForgotPass from "./components/ForgotPass";
import { Grid2 } from '@mui/material';

export const ForgotPassView = () => {
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  let { from } = location.state || { from: { pathname: "/" } };
  let forgotPass = (email) => {
    setIsLoading(true);
    // auth.signin(userName, password).then((res) => {
    //   history.replace(from);
    //   setIsLoading(false);
    //   window.location.reload(false);
    // });
  };

  
  return (
    <div>
      <ForgotPass forgotPass={forgotPass} />
    </div>
  );
}
