import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';

import Paper from '@mui/material/Paper';

import { Grid2 } from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';
// Import all the images from the assets folder
import Image1 from 'assets/analytics_1.png';
import Image2 from 'assets/decission_1.jpg';
import Image3 from 'assets/decission_2.jpg';
import Image4 from 'assets/decission_3.jpg';
import Image5 from 'assets/decission_4.jpg';
import { LoginRouterConfig } from 'navigation/LoginRouterConfig';


const theme = createTheme();

export function LoginPage() {

  // Store the images in an array
  const images = [Image1, Image4, Image5];

  // Select a random image from the array
  const randomImage = images[Math.floor(Math.random() * images.length)];


  return (
    <ThemeProvider theme={theme}>
      <Grid2 container component="main" sx={{ height: '100vh' }}>
        {/* <CssBaseline /> */}
        <Grid2 size={{xs: "false", sm: 4, md: 7}} 
          sx={{
            backgroundImage: `url(${randomImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid2 size={{xs: 12, sm: 8, md: 5}} 
          component={Paper} elevation={6} square>
          <LoginRouterConfig />
        </Grid2>
      </Grid2>
    </ThemeProvider>
  );
}