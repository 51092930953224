import React from "react";
import { Switch, Route } from "react-router-dom";

import { NotFound } from "navigation/NotFound";
import {
  ROOT,
  PAGE_CHAT,
  PAGE_PLOT,
  PAGE_MODEL,
} from "navigation/CONSTANTS";

import PrivateRoute from "./Auth/PrivateRoute";
import { LoginView } from "./Auth/LoginView";
import AutoDetect from "pages/AutoDetect"
import Chat from "pages/Chat";
import Plot from "pages/Plot";
import Model from "pages/Model";

export const RouterConfig = () => {
  return (
    <div>
      <Switch>
        {/* List all public routes here */}
        
        <Route path="/login">
          <LoginView reSign = {true}/>
        </Route>

        {/* List all private/auth routes here */}
        <PrivateRoute exact path={ROOT}>
          <AutoDetect/>
        </PrivateRoute>
  

        <PrivateRoute exact path={PAGE_CHAT}>
          <Chat/>
        </PrivateRoute>

        <PrivateRoute exact path={PAGE_PLOT}>
          <Plot/>
        </PrivateRoute>

        <PrivateRoute exact path={PAGE_MODEL}>
          <Model/>
        </PrivateRoute>

        {/* List a generic 404-Not Found route here */}
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
};
