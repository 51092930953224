import React from "react";
import { Typography } from "@mui/material";


export const NotFound = () => {
    return (
      <>
        <Typography variant="h4">... seems you are trying to find something that do not exist</Typography>
      </>
    );
  };
  