import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types'

import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import { Grid2 } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import dayjs from 'dayjs';
import { Divider } from '@mui/material';
import ChatComponent from 'components/ChatComponent';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';

import 'dayjs/locale/en-gb'; // Import the locale for DD/MM/YYYY format
import { postUserMessage, requestChatMessages, requestEndChat, requestSemanticGroups, requestStartChat } from 'services/chatService';
dayjs.locale('en-gb'); // Set Day.js locale to 'en-gb'

const ChatView = props => {

    const today = new Date();
    const todayAnother = new Date();
    const sevenDaysAgo = new Date(todayAnother.setDate(todayAnother.getDate() - 7));
    const [fromValue, setFromValue] = React.useState(dayjs(sevenDaysAgo.toISOString().split('T')[0]));
    const [toValue, setToValue] = React.useState(dayjs(today.toISOString().split('T')[0]));
    const [chatId, setChatId] = React.useState("");
    const [messages, setMessages] = React.useState([]);
    const [lastMessage, setLastMessage] = React.useState(0);
    const [selectedSemanticGroup, setSelectedSemanticGroup] = React.useState("");
    const [intervalId, setIntervalId] = useState(null); // New state for managing interval ID
    const [semanticGroups, setSemanticGroups] = useState([]);

    const [userMessage, setUserMessage] = useState("");

    const [avoidFetch, setAvoidFetch] = useState(false);


    const chatContainerRef = useRef(null); // Ref for the chat container

    const chatIdRef = useRef(chatId); // Create a ref to hold the current value of chatId
    const lastMessageRef = useRef(lastMessage); // Create a ref to hold the current value of chatId
    const avoidFetchRef = useRef(avoidFetch);

    useEffect(() => {
        chatIdRef.current = chatId; // Update the ref whenever chatId changes
    }, [chatId]);

    useEffect(() => {
        lastMessageRef.current = lastMessage;
    }, [lastMessage]);

    useEffect(() => {
        avoidFetchRef.current = avoidFetch;
    }, [avoidFetch]);

    useEffect(() => {
        // Scroll to the bottom whenever messages change
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        fetchSemanticGroups(props.errorMessage, props.successMessage, props.showError)
        // Function to simulate fetching messages from an API
        const fetchMessages = () => {
            console.log("want to fetch messages", chatIdRef.current); // For debugging, remove later
            if (chatIdRef.current === "" || avoidFetchRef.current) return;
            console.log("Fetching messages..."); // For debugging, remove later
            fetchChat(props.errorMessage, props.successMessage, props.showError);
        };

        // Function to start recurrent fetching
        const startMessageFetching = () => {
            // Set up an interval to fetch messages every 5 seconds
            const id = setInterval(fetchMessages, 2000);
            return id; // Return interval ID for cleanup
        };

        // Start fetching messages when the component mounts
        // const intervalId = startMessageFetching();

        // Cleanup interval when the component unmounts
        return () => {
            clearInterval(intervalId);
            endChat(props.errorMessage, props.successMessage, props.showError)
        };
    }, []); // Empty dependency array ensures this runs only on mount and cleanup on unmount



    function convertToDateTime(dateValue) {
        // If dateValue is a Day.js object, format it directly
        if (dateValue && dateValue.format) {
            return dateValue.format('YYYY-MM-DD') + ' 00:00:00';
        }

        // If it's a string, continue with the previous approach
        const [day, month, year] = dateValue.split('/');
        const date = new Date(year, month - 1, day);
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} 00:00:00`;
    }

    // Handle key press and send on Enter key
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent newline in the text field
            handleSendMessageClick();
        }
    };

    const startChat = (show, hide, showError) => {
        const request = {
            start_date: "2024-10-02 00:00:00",
            end_date: "2024-10-10 00:00:00",
            semantic_group: selectedSemanticGroup
        }
        show()
        requestStartChat(request)
            .then((data) => {
                console.log(data)
                setChatId(data.chat_id)
                hide()
                // startMessageFetching(); // Start fetching messages when chat starts

            }
            )
            .catch((err) => {
                props.errorMessage(err);
                hide()
                showError(err)
            });
    }

    const endChat = (show, hide, showError) => {
        console.log("wanted to end chat: ", chatIdRef.current)
        const request = {
            chat_id: chatIdRef.current
        }
        if (chatIdRef.current === "") return;
        show()
        requestEndChat(request)
            .then((data) => {
                console.log(data)
                setChatId("")
                hide()
            }
            )
            .catch((err) => {
                props.errorMessage(err);
                hide()
                showError(err)
            });
    }

    const fetchChat = (show, hide, showError) => {
        const request = {
            chat_id: chatIdRef.current,
            last_seen_message: lastMessageRef.current
        }
        // show()
        requestChatMessages(request)
            .then((data) => {
                if (avoidFetchRef.current) return;
                console.log(data)
                setMessages(prevMessages => [...prevMessages, ...data.messages]);
                if (data.messages.length > 0) {
                    setLastMessage(data.messages[data.messages.length - 1].ordinal);
                }

            }
            )
            .catch((err) => {
                props.errorMessage(err);
                // hide()
                showError(err)
            });
    }


    const fetchSemanticGroups = (show, hide, showError) => {
        const request = {
            start_date: "2024-10-02 00:00:00",
            end_date: "2024-10-10 00:00:00",
        }
        show()
        requestSemanticGroups(request)
            .then((data) => {
                setSemanticGroups(data)
                hide()
            }
            )
            .catch((err) => {
                props.errorMessage(err);
                hide()
                showError(err)
            });
    }

    const sendUserMessage = (show, hide, showError) => {
        setAvoidFetch(true)
        const request = {
            chat_id: chatId,
            user_message: userMessage
        }
        show()
        postUserMessage(request)
            .then((data) => {
                setUserMessage("")
                hide()
                setAvoidFetch(false)
                fetchChat(props.errorMessage, props.successMessage, props.showError);
            }
            )
            .catch((err) => {
                props.errorMessage(err);
                hide()
                showError(err)
                setAvoidFetch(false)
            });
    }

    const handleRunClick = () => {
        setMessages([])
        setLastMessage(0)
        startChat(props.errorMessage, props.successMessage, props.showError)
    }

    const handleFetchClick = () => {
        fetchChat(props.errorMessage, props.successMessage, props.showError)
    }

    const handleSendMessageClick = () => {
        sendUserMessage(props.errorMessage, props.successMessage, props.showError)
    }

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedSemanticGroup(selectedValue);
    };

    const handleUserMessageChange = (event) => {
        setUserMessage(event.target.value);
    };


    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Container
                maxWidth="false"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    height: 'calc(80vh - 6vh)', // Take into account the footer's height
                }}
            >
                <Paper
                    elevation={0}
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <Grid2 container spacing={3} alignItems={'center'} justify={'center'} marginBottom={3} >
                        <Grid2 size={{ xs: 12, sm: 3 }}>
                            <InputLabel htmlFor="grouped-date-picker-from">From date</InputLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker', 'DatePicker']}>
                                    <DatePicker
                                        value={fromValue}
                                        onChange={(newValue) => setFromValue(newValue)}
                                        format="DD-MM-YYYY" // Specify the date format
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid2>


                        <Grid2 size={{ xs: 12, sm: 3 }}>
                            <InputLabel htmlFor="grouped-date-picker-to">To date</InputLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker', 'DatePicker']}>
                                    <DatePicker
                                        value={toValue}
                                        onChange={(newValue) => setToValue(newValue)}
                                        format="DD-MM-YYYY" // Specify the date format
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid2>

                        <Grid2 size={{ xs: 12, sm: 6 }}>
                            <InputLabel htmlFor="grouped-native-select">Screen</InputLabel>
                            <Select
                                native
                                fullWidth
                                value={selectedSemanticGroup} // Ensures the Select has an empty state initially
                                id="grouped-native-select"
                                label="Grouping"
                                onChange={handleSelectChange} // Set the change handler
                            >
                                <option value="" disabled>Select a group</option> {/* Placeholder option */}
                                {semanticGroups.map((row, index) => (
                                    <option key={index} value={row}>{row}</option>
                                ))}
                            </Select>
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 3 }}>
                            <Button variant="contained" onClick={handleRunClick} endIcon={<PlayCircleFilledWhiteIcon />} disabled={selectedSemanticGroup === ""}>
                                Run
                            </Button>
                            {/* <Button variant="contained" onClick={handleFetchClick} endIcon={<PlayCircleFilledWhiteIcon />}>
                                fetch
                            </Button> */}
                        </Grid2>
                    </Grid2>


                    <Grid2 size={{ xs: 12, sm: 12 }}>
                        <Divider />
                    </Grid2>

                    {/* Chat Section */}
                    <Box
                        ref={chatContainerRef}
                        sx={{
                            flexGrow: 1,             // Ensure the chat box grows and fills the available height
                            overflowY: 'auto',        // Enable scrolling for the chat messages
                            mb: 2,
                            p: 2,
                        }}
                    >


                        {/* Chat messages */}
                        <Box>
                            {messages.map((row, index) => (
                                <ChatComponent text={row.content} fromMe={row.origin_of_message !== 0} />
                            ))}
                        </Box>
                    </Box>

                    {/* Input field for prompt at the bottom */}
                    <Box
                        component="form"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            p: 1,
                            borderTop: '1px solid #ccc',
                            position: 'sticky',   // Stick this to the bottom of the Paper
                            bottom: 0,            // Stay at the bottom of the Paper
                            backgroundColor: 'white',  // Make sure the background is solid
                            zIndex: 1,            // Ensure the input stays on top if needed
                        }}
                        onKeyDown={handleKeyPress} // Handle Enter key press
                    >
                        <TextField
                            fullWidth
                            placeholder="Type your message..."
                            variant="outlined"
                            value={userMessage}
                            multiline           // Allows the TextField to grow vertically
                            maxRows={4}         // Sets the maximum number of rows before scrolling starts
                            onChange={handleUserMessageChange} // Update value on change
                            sx={{ mr: 2 }}
                        />
                        <Button variant="contained" onClick={handleSendMessageClick} endIcon={<SendIcon />} disabled={chatId === ""}>

                        </Button>
                    </Box>
                </Paper>
            </Container>
        </div>
    )

}

ChatView.propTypes = {
    title: PropTypes.string.isRequired,
    errorMessage: PropTypes.func,
    successMessage: PropTypes.func,
}

export default ChatView
