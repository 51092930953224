import React from "react";
// Handle console logs
import "utils/dropConsole";
// Styles
import "fontsource-roboto";
// ROUTER
import { BrowserRouter } from "react-router-dom";
// MUI Theme
import { ThemeProvider } from "@mui/material/styles";
import { light } from "styles/muiTheme";


import './App.css';

import { ProvideAuth } from "navigation/Auth/ProvideAuth";

import LayoutContainer from "Layout/LayoutContainer";
import LoginPage from "pages/LoginPage";


function App() {

  let isLoggedIn = sessionStorage.getItem('authToken') && sessionStorage.getItem('authToken') !== '' && sessionStorage.getItem('authToken') !== 'null';
  let mainContent;
  if (isLoggedIn) {
    mainContent = <LayoutContainer />
  } else {
    mainContent = <LoginPage />;
  }


  return (
    <div>
      
        <ThemeProvider theme={light()}>
          <ProvideAuth>
            <BrowserRouter>
              {mainContent}
            </BrowserRouter>
          </ProvideAuth>
        </ThemeProvider>
    </div>

  );
}

export default App;
