import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';


// ROUTER
import { RouterConfig } from "navigation/RouterConfig";
import AppBarComponent from './components/AppBarComponent';
import DrawerComponent from './components/DrawerComponent';




function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" target="_blank" href="https://www.moveo.one/">
                Moveo one
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;


const mdTheme = createTheme();



function LayoutContent() {




    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };  

    return (
        <div>
            <ThemeProvider theme={mdTheme}>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <AppBarComponent drawerWidth={drawerWidth} open={open} toggleDrawer={toggleDrawer} />
                    <DrawerComponent
                        drawerWidth={drawerWidth}
                        open={open}
                        toggleDrawer={toggleDrawer}/>
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '94vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                        {/* <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}> */}
                        <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                            <RouterConfig />
                        </Container>
                    </Box>
                </Box>
                <Box
                    component="footer"
                    sx={{
                        py: 3,
                        px: 2,
                        mt: 'auto',
                        height: '6vh',
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[200]
                                : theme.palette.grey[800],
                    }}
                >
                    <Container maxWidth="sm">
                        <Copyright />
                    </Container>
                </Box>
            </ThemeProvider>
        </div>
    );
}

export default function LayoutContainer() {
    return <LayoutContent />;
}
