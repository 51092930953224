
import * as React from 'react';
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';

import Box from '@mui/material/Box';

import { Divider, Grid2, Stack } from '@mui/material';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { LineChart, MarkElement, markElementClasses } from '@mui/x-charts/LineChart';

import dayjs from 'dayjs';
import 'dayjs/locale/en-gb'; // Import the locale for DD/MM/YYYY format
import { requestAutoDetect } from 'services/autoDetectService';
import { Link } from 'react-router-dom';
import { axisClasses } from "@mui/x-charts";
import { formatFeatureContent, formatFeatureTitles } from 'utils/stringMappings';


dayjs.locale('en-gb'); // Set Day.js locale to 'en-gb'



const AutoDetectView = props => {
    const name = sessionStorage.getItem('name');
    const today = new Date();
    const todayAnother = new Date();
    const sevenDaysAgo = new Date(todayAnother.setDate(todayAnother.getDate() - 7));
    const [fromValue, setFromValue] = React.useState(dayjs(sevenDaysAgo.toISOString().split('T')[0]));
    const [toValue, setToValue] = React.useState(dayjs(today.toISOString().split('T')[0]));
    const [autoDetectData, setAutoDetectData] = React.useState(null);
    const [outlierIndexes, setOutlierIndexes] = React.useState([]);
    const [xDataSet, setXDataSet] = React.useState([]);


    function convertToDateTime(dateValue) {
        // If dateValue is a Day.js object, format it directly
        if (dateValue && dateValue.format) {
            return dateValue.format('YYYY-MM-DD') + ' 00:00:00';
        }

        // If it's a string, continue with the previous approach
        const [day, month, year] = dateValue.split('/');
        const date = new Date(year, month - 1, day);
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} 00:00:00`;
    }

    // Array of predefined colors
    const colors = ['#FF5733', '#33FF57', '#3357FF', '#F5A623', '#9B59B6'];

    // Default color if index exceeds the colors array length
    const defaultColor = '#EE4B2B'; // Black as default

    // Function to get color based on index
    const getColorForIndex = (index) => {
        // If the index is within the bounds of the colors array, return the corresponding color
        // Otherwise, return the default color
        return index < colors.length ? colors[index] : defaultColor;
    };

    const testGetCollor = (index) => {
        console.log("i: ", index)
    }



    const fetchAutoDetect = (show, hide, showError) => {
        const request = {
            start_date: "2024-10-02 00:00:00",
            end_date: "2024-10-10 00:00:00"
        }
        show()
        requestAutoDetect(request)
            .then((data) => {
                setAutoDetectData(data)
                if (data && data.detected.length > 0) {
                    data.detected.forEach((element) => {
                        let xArray = []
                        let xOutliers = []
                        element.feature_values.x.forEach((xelement, index) => {
                            if (element.outliers.x.includes(xelement)) {
                                xOutliers.push(index)
                            }

                            xArray.push({ title: xelement })
                        });
                        setOutlierIndexes((prevIndexes) => [...prevIndexes, xOutliers]);
                        setXDataSet((prevDataSet) => [...prevDataSet, xArray])
                    });
                }
                hide()
            }
            )
            .catch((err) => {
                props.errorMessage(err);
                hide()
                showError(err)
            });
    }

    const handleRunClick = () => {
        setAutoDetectData(null)
        setOutlierIndexes([])
        setXDataSet([])
        fetchAutoDetect(props.errorMessage, props.successMessage, props.showError)
    }

    return (
        <div>
            <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                <Grid2 container spacing={3}>
                    <Grid2 size={{ xs: 12, md: 12, lg: 12 }}>
                        <Paper
                            elevation={0}
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Grid2 container spacing={3} alignItems={'center'} >
                                <Grid2 size={{ xs: 12, sm: "auto" }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                                            <DatePicker
                                                label="From date"
                                                value={fromValue}
                                                onChange={(newValue) => setFromValue(newValue)}
                                                format="DD-MM-YYYY" // Specify the date format
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid2>


                                <Grid2 size={{ xs: 12, sm: "auto" }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                                            <DatePicker
                                                label="To date"
                                                value={toValue}
                                                onChange={(newValue) => setToValue(newValue)}
                                                format="DD-MM-YYYY" // Specify the date format
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid2>

                                <Grid2 size={{ xs: 12 }} />

                                <Grid2 size={{ xs: 12, sm: 12 }} display={"flex"} justifyContent={"flex-end"}>
                                    <Button variant="contained" endIcon={<SendIcon />} onClick={handleRunClick}>
                                        Run
                                    </Button>
                                </Grid2>
                            </Grid2>
                        </Paper>
                    </Grid2>



                    <Grid2 size={{ xs: 12, md: 12, lg: 12 }}>
                        <Paper
                            elevation={0}
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%'
                            }}
                        >
                            {autoDetectData && autoDetectData.detected.length > 0 &&
                                <Stack sx={{ mt: 2 }} spacing={5}>
                                    {autoDetectData.detected.map((row, index) => (
                                        <>
                                            {index !== 0 &&
                                                <Divider />
                                            }
                                            <Typography variant="h4"
                                                sx={{
                                                    textAlign: 'center', // Center the text inside its own box
                                                }}>
                                                {formatFeatureTitles(row.feature_name)}
                                            </Typography>
                                            {/* <Divider /> */}
                                            {row.explanations.map((explanation, i) => (
                                                <Box key={i} display="flex" alignItems="center" mb={1}>
                                                    {/* Colored square */}

                                                    {/* The explanation text */}
                                                    <Stack>
                                                        <Typography variant="h5">
                                                            {/* [{i}]  {row.outliers.x[i]} */}
                                                            {row.outliers.x[i]}
                                                        </Typography>
                                                        <Typography variant="body1" sx={{ whiteSpace: 'pre-line', ml: 4 }}>
                                                            {formatFeatureContent(explanation)}
                                                        </Typography>
                                                    </Stack>

                                                </Box>
                                            ))}


                                            <Box sx={{ flexGrow: 1, mb: 2 }}>

                                                <LineChart
                                                    xAxis={[
                                                        {
                                                            scaleType: 'point',
                                                            dataKey: 'title'
                                                        },
                                                    ]}
                                                    yAxis={[
                                                        {
                                                            tickLabelStyle: { display: 'none' }, // Hides the labels on the y-axis
                                                            ticks: { show: false },  // Hides the ticks on the y-axis
                                                        },
                                                    ]}
                                                    margin={{ top: 25, right: 20, bottom: 80, left: 20 }}
                                                    dataset={xDataSet[index]}
                                                    series={[{
                                                        data: row.feature_values.y,  // Using row.feature_values.y for y-axis values
                                                        showMark: ({ index: markIndex }) => outlierIndexes[index].includes(markIndex),  // Highlight at index 2
                                                        color: '#76b7b2',
                                                    }]}
                                                    height={400}
                                                    bottomAxis={{
                                                        labelStyle: {
                                                            fontSize: 14,
                                                            transform: `translateY(${
                                                                // Hack that should be added in the lib latter.
                                                                5 * Math.abs(Math.sin((Math.PI * props.angle) / 180))
                                                                }px)`
                                                        },
                                                        tickLabelStyle: {
                                                            angle: 90,
                                                            textAnchor: 'start',
                                                            fontSize: 12,
                                                        },
                                                    }}
                                                    sx={{
                                                        [`& .${markElementClasses.root}`]: {
                                                            stroke: '#EE4B2B',
                                                            scale: '3.6',
                                                            fill: 'transparent',
                                                            strokeWidth: 2,
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            {(index !== autoDetectData.detected.length - 1) &&
                                                <>
                                                    <br />
                                                    <br />
                                                </>
                                            }
                                        </>
                                    ))}
                                </Stack>
                            }

                            {(autoDetectData && autoDetectData.detected.length === 0) &&
                                <Stack sx={{ mt: 2 }} spacing={5}>
                                    <Typography variant="body1" component="h2">
                                        No Autodetected points of interests in selected range.
                                    </Typography>
                                    <Typography variant="body1" component="h2">
                                        Try changing the range or explore data manually in{" "}
                                        <Link to="/chat" style={{ textDecoration: 'none', color: 'blue' }}>
                                            Chat section
                                        </Link>
                                    </Typography>
                                </Stack>
                            }

                            {(!autoDetectData) &&
                                <Stack sx={{ mt: 2 }} spacing={5}>

                                </Stack>
                            }

                        </Paper>
                    </Grid2>
                </Grid2>
            </Container>

        </div >
    )

}

AutoDetectView.propTypes = {
    title: PropTypes.string.isRequired
}

export default AutoDetectView
