import React from "react";
import AutoDetectView from "./AutoDetectView";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export const AutoDetectContainer = () => {

  // SnackBar
  const [backDrop, setBackDrop] = React.useState(false);
  const [snackBar, setSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');

  const handleOpenSnackBarClick = () => {
    setSnackBarOpen(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  };

  const handleErrMessage = (message) => {
    setSnackBarMessage(message);
    handleOpenSnackBarClick();
  }

  const handleSuccessMessage = (message) => {
    setSnackBarMessage(message);
    handleOpenSnackBarClick();

  }

  const showBackDrop = () => {
    setBackDrop(true)
  }

  const hideBackDrop = () => {
    setBackDrop(false)
  }

  return (
    <div>
      <AutoDetectView title="HOME" errorMessage={showBackDrop} successMessage={hideBackDrop} showError={handleErrMessage}/>
      <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backDrop}
                onClick={hideBackDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={snackBar}
                autoHideDuration={6000}
                onClose={handleCloseSnackBar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert
                    onClose={handleCloseSnackBar}
                    severity="success"
                    elevation={6}
                    variant="filled"
                    sx={{ width: '100%' }}>
                    {snackBarMessage}
                </Alert>
            </Snackbar>
    </div>
  );
}
