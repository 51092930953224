import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { Grid2 } from '@mui/material';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BASE_URL } from 'services/CONSTANTS';
import moveo_one_logo from 'assets/moveo_one_logo.png';


const theme = createTheme();

export default function SignIn(props) {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    
    props.signin(data.get('email'), data.get('password'));
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} src={moveo_one_logo}/>
          {props.reSign && (
            <Typography component="h1" variant="h5">
              Session expired, please sign
            </Typography>
          )}

          {!props.reSign && (
            <Typography component="h1" variant="h5">
              Sign in to Moveo One {BASE_URL.includes("127.0.0.1") ? "(LOCALHOST)" : ""}
            </Typography>
          )}

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              defaultValue={props.reSign ? sessionStorage.getItem('email') : null}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid2 container>
              {/* <Grid2 item xs>
                <Link href="forgot-pass" variant="body2">
                  Forgot password?
                </Link>
              </Grid2> */}
              {/*{!props.reSign && (*/}
              {/*  <Grid2 item>*/}
              {/*    <Link href="signup" variant="body2">*/}
              {/*      {"Don't have an account? Sign Up"}*/}
              {/*    </Link>*/}
              {/*  </Grid2>*/}
              {/*)}*/}
            </Grid2>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}