import * as React from 'react';
import PropTypes from 'prop-types'

import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import { Grid2, Stack } from '@mui/material';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { LineChart, MarkElement } from '@mui/x-charts/LineChart';

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import dayjs from 'dayjs';


const PlotView = props => {

    const [value, setValue] = React.useState(dayjs('2022-04-17'));

    return (
        <div>
            <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
                <Grid2 container spacing={3}>
                    <Grid2 size={{ xs: 12, md: 12, lg: 12 }}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Grid2 container spacing={3} alignItems={'center'} justify={'center'} >

                                <Grid2 size={{ xs: 12, sm: 3 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                                            <DatePicker
                                                label="Controlled picker"
                                                value={value}
                                                onChange={(newValue) => setValue(newValue)}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid2>


                                <Grid2 size={{ xs: 12, sm: 3 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                                            <DatePicker
                                                label="Controlled picker"
                                                value={value}
                                                onChange={(newValue) => setValue(newValue)}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid2>

                                <Grid2 size={{ sm: 6 }}>
                                    
                                </Grid2>

                                <Grid2 size={{ xs: 12, sm: 6 }}>
                                    <InputLabel htmlFor="grouped-native-select">X axis</InputLabel>
                                    <Select
                                        native fullWidth defaultValue="" id="grouped-native-select" label="Grouping">
                                        
                                            <option value={3}>Screen 1</option>
                                            <option value={4}>Screen 2</option>
                                            <option value={4}>Screen 3</option>
                                            <option value={4}>Screen 4</option>
                                            <option value={4}>Screen 5</option>
                                            <option value={4}>Screen 6</option>
                                            <option value={4}>Screen 7</option>
                                            <option value={4}>Screen 8</option>
                                            <option value={4}>Screen 9</option>
                                            <option value={4}>Screen 10</option>
                                            <option value={4}>Screen 11</option>
                                            <option value={4}>Screen 12</option>
                                        
                                    </Select>
                                </Grid2>

                                <Grid2 size={{ xs: 12, sm: 6 }}>
                                    <InputLabel htmlFor="grouped-native-select">Y axis</InputLabel>
                                    <Select
                                        native fullWidth defaultValue="" id="grouped-native-select" label="Grouping">
                                        <optgroup label="Featured">
                                            <option value={1}>Time to react</option>
                                            <option value={2}>Cognitive score</option>
                                        </optgroup>
                                        <optgroup label="All">
                                            <option value={3}>Time per char</option>
                                            <option value={4}>Complexity</option>
                                        </optgroup>
                                    </Select>
                                </Grid2>

                                <Grid2 size={{ xs: 12, sm: 12 }}>
                                    <Button variant="contained" endIcon={<SendIcon />}>
                                        Run
                                    </Button>
                                </Grid2>
                            </Grid2>
                        </Paper>
                    </Grid2>



                    <Grid2 size={{ xs: 12, md: 12, lg: 12 }}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%'
                            }}
                        >
                            <Stack sx={{ mt: 2 }} spacing={5}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <LineChart
                                        xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                                        series={[
                                            {
                                                data: [2, 5.5, 2, 8.5, 1.5, 5],
                                                showMark: ({ index }) => index === 2,
                                            },
                                        ]}
                                        height={300}
                                        slots={{
                                            higlight: <MarkElement shape='cross' />
                                        }}
                                    />
                                </Box>
                            </Stack>
                        </Paper>
                    </Grid2>
                </Grid2>
            </Container>


        </div>
    )

}

PlotView.propTypes = {
    title: PropTypes.string.isRequired,
    errorMessage: PropTypes.func,
    successMessage: PropTypes.func,
}

export default PlotView
