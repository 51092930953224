import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles';
import { useHistory } from "react-router-dom";


import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';

import { deepPurple } from '@mui/material/colors';
import { useProvideAuth } from 'navigation/Auth/useProvideAuth';


const colorGreen = '#44b700';
const colorRed = '#fc0303';

let color = colorGreen;

const userMenu = ['Profile', 'Logout'];

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: `${color}`,
        color: `${color}`,
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

function UserMenuComponent(props) {
    const auth = useProvideAuth();
    
    let history = useHistory();


    const [anchorElUser, setAnchorElUser] = React.useState(null);


    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.target);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);

    };

    const onClickUserMenu = (setting) => {
        if (setting === 'Logout') {
            let { from } = { from: { pathname: "/" } };
            auth.signout();
            history.replace(from);
            window.location.reload(false);

        }
        setAnchorElUser(null);
    };

    return (
        <div>
            <Box sx={{ flexGrow: 0 }}>
                <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                >
                    <IconButton
                        onClick={handleOpenUserMenu}
                        sx={{ p: 0 }}>
                        <Avatar sx={{ bgcolor: deepPurple[500] }}>OP</Avatar>
                    </IconButton>
                </StyledBadge>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    {userMenu.map((setting) => (
                        <MenuItem key={setting} onClick={() => onClickUserMenu(setting)}>
                            <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
        </div >

    );
}

export default UserMenuComponent;