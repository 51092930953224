import React from "react";
import { Switch, Route } from "react-router-dom";

import { NotFound } from "navigation/NotFound";
import { ROOT_LOGIN, SIGN_UP, FORGOT_PASS } from "navigation/CONSTANTS";




import { SignUpView } from "./Auth/SignUpView";
import { LoginView } from "./Auth/LoginView";
import { ForgotPassView } from "./Auth/ForgotPassView";

export const LoginRouterConfig = () => {
  return (
    <div>
      <Switch>
        {/* List all public routes here */}
        <Route exact path={ROOT_LOGIN}>
          <LoginView/>
        </Route>

        <Route exact path={SIGN_UP}>
          <SignUpView/>
        </Route>

        <Route exact path={FORGOT_PASS}>
          <ForgotPassView/>
        </Route>
        {/* List a generic 404-Not Found route here */}
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
};
