import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "./ProvideAuth";

import SignUp from "./components/SignUp";

import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export const SignUpView = () => {
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  let { from } = { from: { pathname: "/" } };
  let signup = (userName, password, firstName, lastName, email, promotions) => {
    if (!validation(userName, password, firstName, lastName, email, promotions)) {
      return;
    }
    setIsLoading(true);
    // signUp(userName, password, firstName, lastName, email, promotions).then((res) => {
    //   history.replace(from);
    //   setIsLoading(false);
    //   // window.location.reload(false);
    // }).catch((err) => {
    //   handleErrMessage(err);
    // });
  };

  const validation = (userName, password, firstName, lastName, email, promotions) => {
    
    try {
    
      if (userName.includes("@")) {
        handleErrMessage("username can't contain '@'");
        return false;
      }
      if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
        handleErrMessage("not valid email");
        return false;
      }
  

      if (!firstName) {
        handleErrMessage("need to enter First Name");
        return false;
      }

      if (!lastName) {
        handleErrMessage("need to enter Last Name");
        return false;
      }

      
    } catch (err) {
      handleErrMessage(err.message);
      return false;
    }
    return true;
  }

  // SnackBar
  const [snackBar, setSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');

  const handleErrMessage = (message) => {
    setSnackBarMessage(message);
    handleOpenSnackBarClick();
  }

  const handleOpenSnackBarClick = () => {
    setSnackBarOpen(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading && (
        <LinearProgress />
      )}
      {!isLoading && (
        <SignUp signup={signup} />
      )}
      <Snackbar
        open={snackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert
          onClose={handleCloseSnackBar}
          severity="error"
          elevation={6}
          variant="filled"
          sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
