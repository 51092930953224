import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types'

import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import { Grid2 } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import TextField from '@mui/material/TextField';

import dayjs from 'dayjs';
import { Divider } from '@mui/material';

import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import LinearProgress from '@mui/material/LinearProgress';

import 'dayjs/locale/en-gb'; // Import the locale for DD/MM/YYYY format

import { Stack } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { formatFeatureContent, formatFeatureTitles } from 'utils/stringMappings';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import Switch from '@mui/material/Switch';


dayjs.locale('en-gb'); // Set Day.js locale to 'en-gb'

const label = { inputProps: { 'aria-label': 'Switch demo' } };


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '60%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{typeof props.absoluteNumber === 'number' ? `${Math.round(
                    props.absoluteNumber,
                )}%` : "-"}</Typography>
            </Box>
            {props.absoluteNumber === 100 && <CheckIcon sx={{ color: "green", ml: "10px" }} />}
        </Box>
    );
}

let exampleJson = {
    'userId': 'guuid',
    'modelId': 'guuid',
    'modelScore': 0.5,
    'modelDesctiption': 'The model is built in order to detect drop of user attention',
    'modelPattern': '',
    'timestamp': '123412355'
}

const ModelView = props => {

    const [value, setValue] = React.useState([]);
    const [example, setExample] = React.useState(exampleJson);
    const [threashold, setThreashold] = React.useState(0.5);
    const [description, setDescription] = React.useState("The model is built in order to detect drop of user attention");

    const handleThresholdChange = (event) => {
        setThreashold(event.target.value);
        setExample((prevExample) => ({
            ...prevExample,
            modelScore: parseFloat(event.target.value)
        }));
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
        setExample((prevExample) => ({
            ...prevExample,
            modelDesctiption: event.target.value
        }));
    };


    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Container
                maxWidth="false"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    height: 'calc(90vh - 6vh)', // Take into account the footer's height
                }}
            >
                <Paper
                    elevation={0}
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >

                    <Stack spacing={2}>
                        <Typography variant="body2" color="text.secondary">Data necessery for training the model:</Typography>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgressWithLabel absoluteNumber={67} value={67} />
                        </Box>
                        {/* <Divider /> */}
                        <Typography variant="body2" color="text.secondary">Data necessery feature selection:</Typography>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgressWithLabel absoluteNumber={100} value={100} />
                        </Box>
                        <Divider />
                        <br />
                    </Stack>





                    <Grid2 container spacing={3} alignItems={'center'} justify={'center'} marginBottom={3} >
                        <Grid2 size={{ xs: 12, sm: 10 }}>
                            <Autocomplete
                                fullWidth
                                multiple
                                id="fixed-tags-demo"
                                value={value}
                                onChange={(event, newValue) => {
                                    setValue([...newValue]);
                                    setExample((prevExample) => ({
                                        ...prevExample,
                                        modelPattern: newValue.map(obj => formatFeatureContent(obj.title).replace("'", "").replace("'", "")).join(' ')
                                    }));
                                }}
                                options={[...availableFeatures, ...availableOperators].map((el) => ({ title: el.title, type: el.type }))}
                                groupBy={(option) => option.type}
                                // options={[...availableFeatures, ...availableOperators]}
                                getOptionLabel={(option) => formatFeatureTitles(option.title)}
                                getOptionDisabled={(option) =>
                                    (option.type === "operator" && value.length > 0 && value[value.length - 1].type === "operator") ||
                                    (option.type === "feature" && value.length > 0 && value[value.length - 1].type === "feature") ||
                                    (option.type === "operator" && value.length === 0)
                                }
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) => {
                                        const { key, ...tagProps } = getTagProps({ index });
                                        return (
                                            <Chip
                                                key={key}
                                                label={formatFeatureContent(option.title).replace("'", "").replace("'", "")}
                                                {...tagProps}
                                                // disabled={fixedOptions.includes(option)}
                                                sx={{
                                                    height: option.type === 'feature' ? '40px' : '24px', // Set height based on option.type
                                                    backgroundColor: option.type === 'feature' ? '#f44336' : '1976d2',
                                                    color: option.type === 'feature' ? '#fff' : '#000' // Set text color (white) for contrast
                                                }}
                                            />
                                        );
                                    })
                                }
                                renderInput={(params) => (
                                    <TextField {...params} label="Compound model" placeholder="Feature + operator" />
                                )}
                            />
                        </Grid2>

                        <Grid2 size={{ xs: 12, sm: 2 }}>
                            <TextField
                                placeholder="Treashold"
                                variant="outlined"
                                value={threashold}
                                onChange={handleThresholdChange} // Update value on change
                                sx={{ mr: 2 }}
                            />
                        </Grid2>

                        <Grid2 size={{ xs: 12, sm: 10 }}>
                            <TextField
                                fullWidth
                                placeholder="Description"
                                variant="outlined"
                                value={description}
                                onChange={handleDescriptionChange} // Update value on change
                                sx={{ mr: 2 }}
                            />
                        </Grid2>

                        <Grid2 size={{ xs: 12, sm: 12 }}>
                            Example of the model delivered to a web-hook: {<div><pre>{JSON.stringify(example, null, 2)}</pre></div>}
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 12 }}>
                            <TextField
                                fullWidth
                                placeholder="Paste the web-hook endpoint"
                                variant="outlined"
                                value={'https://moveo-one-api-web-app.azurewebsites.net/api/analytic/event-sync'}
                                multiline           // Allows the TextField to grow vertically
                                maxRows={4}         // Sets the maximum number of rows before scrolling starts
                                // onChange={handleUserMessageChange} // Update value on change
                                sx={{ mr: 2 }}
                            />
                        </Grid2>



                        <Grid2 />
                        <Grid2 size={{ xs: 12, sm: 3 }}>
                            <Button variant="contained" endIcon={<PlayCircleFilledWhiteIcon />} >
                                Build
                            </Button>
                        </Grid2>



                    </Grid2>

                    <Grid2 container spacing={3} alignItems={'center'} justify={'center'} marginBottom={3} >

                        <Grid2 size={{ xs: 12, sm: 3 }}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                            uuid
                                        </Typography>
                                        <CheckIcon sx={{ color: "green" }} />
                                    </Box>
                                    <Typography variant="h5" component="div">
                                        User engage
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>Sto and TNtCS and StO or CAS</Typography>
                                    <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>The model is built in order to detect drop of user attention</Typography>
                                    <Typography variant="body2">
                                        hook:
                                        <br />
                                        {'...ites.net/api/analytic/event-sync-model'}
                                    </Typography>
                                </CardContent>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <CardActions>
                                        <Button size="small">more</Button>
                                    </CardActions>
                                    <Switch {...label} defaultChecked />
                                </Box>
                            </Card>
                        </Grid2>


                        <Grid2 size={{ xs: 12, sm: 3 }}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                            uuid
                                        </Typography>
                                        <RotateLeftIcon />
                                    </Box>
                                    <Typography variant="h5" component="div">
                                        User drop
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>Sto and TNtCS and StO or CAS</Typography>
                                    <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>The model is built in order to detect drop of user attention</Typography>
                                    <Typography variant="body2">
                                        hook:
                                        <br />
                                        {'...ites.net/api/analytic/event-sync'}
                                    </Typography>
                                </CardContent>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <CardActions>
                                        <Button size="small">more</Button>
                                    </CardActions>
                                    <Switch {...label} disabled />
                                </Box>
                            </Card>
                        </Grid2>

                    </Grid2>


                    <Grid2 size={{ xs: 12, sm: 12 }}>
                        <Divider />
                    </Grid2>

                </Paper>
            </Container>
        </div>
    )

}

ModelView.propTypes = {
    title: PropTypes.string.isRequired,
    errorMessage: PropTypes.func,
    successMessage: PropTypes.func,
}

export default ModelView



const availableOperators = [
    { title: 'and', type: 'operator' },
    { title: 'or', type: 'operator' },
    { title: 'x', type: 'operator' },
]

const availableFeatures = [
    { title: 'Cognitive_attention_score', type: 'feature' },
    { title: 'FirstInteractionTime', type: 'feature' },
    { title: 'InteractionsPerElementSelectList_item', type: 'feature' },
    { title: 'TimePerCharacter', type: 'feature' },
    { title: 'VisitsPerElementScreen', type: 'feature' },
    { title: 'TotalTime', type: 'feature' },
    { title: 'InteractionsPerElementClickButton_next', type: 'feature' },
    { title: 'Uncertanty_per_screen', type: 'feature' },
    { title: 'Changing_the_option', type: 'feature' },
]
