import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import HomeIcon from '@mui/icons-material/Home';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

import DifferenceIcon from '@mui/icons-material/Difference';
import BarChartIcon from '@mui/icons-material/BarChart';
import PsychologyIcon from '@mui/icons-material/Psychology';

import { ROOT, PAGE_CHAT, PAGE_PLOT, PAGE_MODEL } from 'navigation/CONSTANTS';
import { useHistory } from "react-router-dom";


export function MenuComponent(props) {

  const history = useHistory();

  const [selected, setSelected] = React.useState(ROOT);

  const goTo = (path) => {
    setSelected(path)
    history.replace(path || ROOT);
  }

  return (
    <>
      <React.Fragment>
        <ListSubheader component="div" inset>
          Essentials
        </ListSubheader>
        <ListItemButton
          onClick={() => { goTo(ROOT) }}
          sx={{
            backgroundColor: selected === ROOT ? "#dbe9ff" : "inherit",
            '&:hover': {
              backgroundColor: selected === ROOT ? "#dbe9ff" : "#f0f0f0", // Light gray on hover if not selected
              cursor: 'pointer', // Add pointer cursor on hover
            }
          }}
        >
          <ListItemIcon>
            <AutoGraphIcon sx={{ color: selected === ROOT ? "#3d84f7" : "inherit" }} />
          </ListItemIcon>
          <ListItemText primary="Auto Detect" secondary="(live demo)"  />
        </ListItemButton>

        <ListItemButton
          onClick={() => { goTo(PAGE_CHAT) }}
          sx={{
            backgroundColor: selected === PAGE_CHAT ? "#dbe9ff" : "inherit",
            '&:hover': {
              backgroundColor: selected === PAGE_CHAT ? "#dbe9ff" : "#f0f0f0", // Light gray on hover if not selected
              cursor: 'pointer', // Add pointer cursor on hover
            }
          }}
        >
          <ListItemIcon>
            <InsertCommentIcon sx={{ color: selected === PAGE_CHAT ? "#3d84f7" : "inherit" }} />
          </ListItemIcon>
          <ListItemText primary="Chat"  secondary="(live demo)" />
        </ListItemButton>

        <ListItemButton
          disabled
          onClick={() => { goTo(PAGE_PLOT) }}
          sx={{
            backgroundColor: selected === PAGE_PLOT ? "#dbe9ff" : "inherit",
            '&:hover': {
              backgroundColor: selected === PAGE_PLOT ? "#dbe9ff" : "#f0f0f0", // Light gray on hover if not selected
              cursor: 'pointer', // Add pointer cursor on hover
            }
          }}
        >
          <ListItemIcon>
            <BarChartIcon sx={{ color: selected === PAGE_PLOT ? "#3d84f7" : "inherit" }} />
          </ListItemIcon>
          <ListItemText primary="Plot" />
        </ListItemButton>

        <ListItemButton
          onClick={() => { goTo(PAGE_MODEL) }}
          sx={{
            backgroundColor: selected === PAGE_MODEL ? "#dbe9ff" : "inherit",
            '&:hover': {
              backgroundColor: selected === PAGE_MODEL ? "#dbe9ff" : "#f0f0f0", // Light gray on hover if not selected
              cursor: 'pointer', // Add pointer cursor on hover
            }
          }}
        >
          <ListItemIcon>
            <PsychologyIcon sx={{ color: selected === PAGE_MODEL ? "#3d84f7" : "inherit" }} />
          </ListItemIcon>
          <ListItemText primary="Build Model" secondary="(UI Preview)" />
        </ListItemButton>

      </React.Fragment>
    </>
  );
};