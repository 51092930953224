// main router
export const ROOT = "/";
export const LOGIN = "/login";
export const DASHBOARD = "/dashboard";
export const UN_AUTH1 = "/unauthorized1";
export const AUTH_PAGE1 = "/authorized1";

export const PAGE_CHAT = "/chat";
export const PAGE_PLOT = "/plot";
export const PAGE_MODEL = "/model";



// login router
export const ROOT_LOGIN = "/";
export const SIGN_UP = "/signup";
export const FORGOT_PASS = "/forgot-pass";