/**
 * All API urls and other constants will reside here.
 * It is always a good idea to keep a local copy of all API response to 
 * keep your app working for UI changes and 
 * make it independent of network requirements.
 * 
 * They need to be categorised and grouped together as:
 *  - Actual endpoints url.
 *  - Local data .json file path.
 * At a moment only one group should be uncommented.
 * 
 * Other way to deal with this is to name every json file as per your service endpoint and use a basepath variable.
 * Toggle this basePath variable between "actual-domain.com/" or "/data/".
 */

export const BASE_URL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://moveo-one-demo-api-web-app-aqepcgaeg4c7cvba.northeurope-01.azurewebsites.net';
export const API_URL = '/api'

// Actual endpoints. Uncomment below section to use actual data.
export const USER_LOGIN_URL = () => `${BASE_URL}/login`;
export const AUTO_DETECT_URL = () => `${BASE_URL}${API_URL}/analytic/auto-detect`;
export const START_CHAT_URL = () => `${BASE_URL}${API_URL}/analytic/start-chat`;
export const FETCH_SEMANTIC_GROUP_URL = () => `${BASE_URL}${API_URL}/analytic/fetch-semantic-groups`;
export const FETCH_MESSAGES_URL = () => `${BASE_URL}${API_URL}/analytic/fetch-chat-messages`;
export const POST_MESSAGE_URL = () => `${BASE_URL}${API_URL}/analytic/continue-chat`;
export const END_CHAT_URL = () => `${BASE_URL}${API_URL}/analytic/end-chat`;
