import { createTheme } from "@mui/material";

const global = {
  textRight: {
    textAlign: "right",
  },
  mygrey: "rgba(0, 0, 0, 0.5)",
};



export const dark = () => (createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: "#040f3d",
    },
    secondary: {
      main: "#757575",
    },
  },
  typography: {
    button: {
    },
  }
}))

export const light = ()=> (createTheme({
  palette: {
    type: 'light',
    primary: {
      main: "#4bffa5",
    },
    secondary: {
      main: "#040f3d",
    },
  }
}))
