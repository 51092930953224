export const featureTitleMapping = {
    "FirstInteractionTime": "Initiating the interaction (ItI)",
    "InteractionsPerElementSelectList_item": "Select the option - decision (StO)",
    "TimePerCharacter": "Time needed to comprehend screen (TNtCS)",

    "Cognitive_attention_score": "Cognitive Attention Score (CAS)",
    "VisitsPerElementScreen": "Viewing the element on screen (VEoS)",
    "TotalTime": "Total time User spent (TTU)",
    "InteractionsPerElementClickButton_next": "Interaction with clickable elements (IwCE)",
    "Uncertanty_per_screen": "Uncertanty index per scree (UIpS)",
    "Changing_the_option": "Changing the option (CtO)",
};

export const featureContentMapping = {
    "FirstInteractionTime": "'ItI'",
    "InteractionsPerElementSelectList_item": "'StO'",
    "TimePerCharacter": "'TNtCS'",

    "Cognitive_attention_score": "CAS",
    "VisitsPerElementScreen": "VEoS",
    "TotalTime": "TTU",
    "InteractionsPerElementClickButton_next": "IwCE",
    "Uncertanty_per_screen": "UIpS",
    "Changing_the_option": "CtO",
};


// Helper function to get mapped title string
export const getFeatureTitleMapping = (key) => {
    return featureTitleMapping[key] || key;
};

// Helper function to get mapped content string
export const getFeatureContentMapping = (key) => {
    return featureContentMapping[key] || key;
};

// Utility function to format a string with mappings from both title and content mappings
export const formatFeatureTitles = (inputString) => {
    let formattedString = inputString;

    // Replace occurrences from stringTitleMappings
    for (const [key, value] of Object.entries(featureTitleMapping)) {
        const regex = new RegExp(`\\b${key.replace(/\s+/g, '\\s+')}\\b`, 'gi');
        formattedString = formattedString.replace(regex, value);
    }

    return formattedString;
};


// Utility function to format a string with mappings from both title and content mappings
export const formatFeatureContent = (inputString) => {
    let formattedString = inputString;

    // Replace occurrences from stringContentMappings
    for (const [key, value] of Object.entries(featureContentMapping)) {
        const regex = new RegExp(`\\b${key.replace(/\s+/g, '\\s+')}\\b`, 'gi');
        formattedString = formattedString.replace(regex, value);
    }

    return formattedString;
};