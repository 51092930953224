import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "./ProvideAuth";
import SignIn from "navigation/Auth/components/SignIn";
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export const LoginView = (props) => {
  const reSign = props ? props.reSign : false;

  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  // SnackBar
  const [snackBar, setSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');

  if (reSign) {
    auth.removeUser();
  }

  const handleErrMessage = (message) => {
    setSnackBarMessage(message);
    handleOpenSnackBarClick();
}

  const handleOpenSnackBarClick = () => {
      setSnackBarOpen(true);
  };

  const handleCloseSnackBar = (event, reason) => {
      if (reason === 'clickaway') {
          return;
      }
      setSnackBarOpen(false);
      setIsLoading(false);
  };

  let { from } = location.state || { from: { pathname: "/" } };
  let login = (email, password) => {
    setIsLoading(true);
    auth.signin(email, password).then((res) => {

      history.replace(from);
      window.location.reload(false);
      // setIsLoading(false);
    }).catch((err) => {
      handleErrMessage(err);
    });
  };


  return (
    <div>
      {isLoading && (
        <LinearProgress />
      )}
      {!isLoading && (
        <SignIn signin={login} reSign = {reSign} />
      )}
      <Snackbar
        open={snackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert
          onClose={handleCloseSnackBar}
          severity="error"
          elevation={6}
          variant="filled"
          sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
} 
