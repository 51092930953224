// All user related database operations can be defined here.
import { SYSTEM_ERROR } from 'config/CONSTANTS';
import axios from 'axios'
import { USER_LOGIN_URL } from "./CONSTANTS";

/**
 * Sign in.
 */
export const signIn = (email, password) => {
    

    const user = {
        username: email,
        password: password
    };

    return new Promise((resolve, reject) => {
        try {
            // do an SDK, DB call or API endpoint axios call here and return the promise.
            axios
                .post(USER_LOGIN_URL(), user)
                .then((res) => {
                    
                    console.log(res);
                    // Setting the token into axios defaults and session storage
                    sessionStorage.setItem('authToken', res.data.token)
                    sessionStorage.setItem('name', email);
                    sessionStorage.setItem('roles', 'admin');
                    axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('authToken');
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log(err);
                    if (err && err.response && err.response.data) {
                        reject(err.response.data.message);
                    } else {
                        reject(err.message);
                    }
                });
        } catch (error) {
            console.error("in userService > signIn, Err===", error);
            reject(SYSTEM_ERROR);
        }
    });
};


export const signOut = () => {
    // Setting the token into axios defaults and session storage
    sessionStorage.setItem('authToken', null)
    axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('authToken');
};


